
import axios from "axios";
import { Component, Vue } from "vue-property-decorator";

import Tabs from "@/components/Tabs.vue";

import Communication from "@/views/Communication.vue";
import Structure from "@/views/Structure.vue";

import Chat from "@/panels/Chat.vue";
import Empty from "@/panels/Empty.vue";
import User from "@/panels/User.vue";
import Profile from "@/panels/Profile.vue";
import EditNote from "@/panels/EditNote.vue";
import MailInfo from "@/panels/MailInfo.vue";
import StatInfo from "@/panels/StatInfo.vue";
import StatSend from "@/panels/StatSend.vue";
import MailHistory from "@/panels/MailHistory.vue";
import ChangePassword from "@/panels/ChangePassword.vue";
import ProhibitionList from "@/panels/ProhibitionList.vue";
import SuccessStatSend from "@/panels/SuccessStatSend.vue";

import BlockBalance from "@/modals/BlockBalance.vue";
import ConfirmLogout from "@/modals/ConfirmLogout.vue";
import ErrorResponse from "@/modals/ErrorResponse.vue";

import { AuthorizedHeadersDefault } from "@/types/axios";

@Component({
  components: {
    ErrorResponse,
    SuccessStatSend,
    ConfirmLogout,
    Tabs,
    Communication,
    Structure,
    Chat,
    Empty,
    User,
    Profile,
    EditNote,
    MailHistory,
    MailInfo,
    BlockBalance,
    StatInfo,
    StatSend,
    ChangePassword,
    ProhibitionList
  }
})
export default class TabsScreen extends Vue {

  get token() {
    return this.$store.getters['getToken'];
  }

  created() {
    const headers: AuthorizedHeadersDefault = axios.defaults.headers;

    headers["Authorization"] = `Bearer ${this.token}`;

    this.$store.dispatch('loadMe');
  }

}
