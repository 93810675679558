import Vue from 'vue';
import Vuex from 'vuex';

import user from './user';
import chat from './chat';
import contacts from './contacts';
import structure from './structure';
import mail from './mail';
import notification from './notification';

import App from './app';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    options: {
      baseUrl: null,
      cdnUrl: null,
      token: null,
      toUser: null,
      mode: null,
      updateInterval: 30
    }
  },
  mutations: {
    setWidgetConfig(state, payload) {
      state.options = {
        ...state.options,
        ...payload
      };
    }
  },
  getters: {
    getToken: state => state.options.token,
    getUpdateInterval: state => state.options.updateInterval * 1000,
    getBaseUrl: state => state.options.baseUrl,
    getCdnUrl: state => state.options.cdnUrl,
    getToUser: state => state.options.toUser,
    getMode: state => state.options.mode
  },
  modules: {
    user,
    chat,
    contacts,
    structure,
    mail,
    notification,
    app: App
  }
});
