<template>
  <div class="c-chat">
    <Header
      v-if="selectedContact.full_name"
      class="header"
      :name="selectedContact.full_name"
      :description="selectedContact.rank"
      @click-user="goProfile"
    >
      <div v-if="app.isMobile" @click="goBack">
        <img :src="cdn.back" alt="">
      </div>
      <template #avatar>
        <Avatar :src="selectedContactAvatar" :size="36"></Avatar>
      </template>
    </Header>
    <!--    If chat wasn't loaded or loaded but with messages-->
    <template v-if="!wasLoaded || (wasLoaded && messages.length !== 0)">
      <virtual-list
        v-if="!loading"
        ref="chat"

        class="chat"
        item-class="message-wrapper"

        :data-key="'date'"
        :data-sources="messages"
        :data-component="itemComponent"

        @totop="loadMessages"
        @scroll.native="handleScroll"
      >
        <div slot="header" v-show="fetching" class="fetching">
          <Spinner :absolute="false" />
        </div>
      </virtual-list>
      <div
        v-else
        class="chat chat-skeleton"
      >
        <skeleton-message class="message-wrapper"></skeleton-message>
        <skeleton-message class="message-wrapper" isOutgoing></skeleton-message>
      </div>
    </template>
    <template v-else>
      <div class="empty">
        <img :src="cdn.message" alt="">
        <div v-if="!selectedContact.notExist" class="text">{{ $t('placeholders.startChat') }}</div>
        <div v-else class="text">{{ $t('placeholders.notExistUser') }}</div>
      </div>
    </template>
    <div v-if="!app.isMobile" class="fast-scroll-wrapper">
      <div :class="{ 'fast-scroll': true, 'fast-scroll-active': showFastScroll }" @click="setChatToBottom">
        <div v-if="unreadMessagesCounter" class="counter">{{ unreadMessagesCounter }}</div>
        <img :src="cdn.unread" alt="">
      </div>
    </div>
    <div v-if="!selectedContact.notExist" class="writebar-wrapper">
      <WriteBar v-if="!loading" class="writebar"  />
      <skeleton
        v-else
        class="writebar"
        :width="460"
        :height="48"
        :rounded="true"
      ></skeleton>
    </div>
  </div>
</template>

<script>
import cdnSource from "@/functions/cdnSource";

import Skeleton from "@/components/Skeleton";
import WriteBar from "@/components/Chat/WriteBar";
import Spinner from "@/components/Spinner";
import Avatar from "@/components/Avatar";
import Header from "@/components/Chat/Header";
import SkeletonMessage from "@/components/Skeleton/Message";

import MessageVirtual from "@/components/VirtualList/MessageVirtual";

export default {
  name: 'Chat',
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  components: {
    Spinner,
    WriteBar,
    Avatar,
    Header,
    SkeletonMessage,
    Skeleton
  },
  data() {
    return {
      firstLoading: true,
      chatHeight: '360px',
      ro: null,
      itemComponent: MessageVirtual,
      overflow: false,
      showFastScroll: false,
      fetching: false,
      cdn: {
        unread: cdnSource('@/assets/img/app/arrow-down.svg'),
        back: cdnSource('@/assets/img/app/back.svg'),
        message: cdnSource('@/assets/img/message.svg')
      }
    };
  },
  computed: {
    messages() {
      const messages = this.$store.getters['getMessages'];

      let resultMessages = {};

      messages.forEach((msg) => {
        const dt = this.$moment
          .utc(msg.tm)
          .local()
          .format('LL');

        let messages_set = resultMessages[dt] || [];

        messages_set.push(msg);

        resultMessages[dt] = messages_set;
      });

      return Object.keys(resultMessages).map((key) => ({ date: key, messagesSet: resultMessages[key] }));
    },
    app() {
      return this.$store.state.app;
    },
    contacts() {
      return this.$store.getters['getContacts'];
    },
    baseUrl() {
      return this.$store.getters['getBaseUrl'];
    },
    selectedContact() {
      return this.$store.getters['getSelectedContact'];
    },
    messagesFetchingDisabled() {
      return this.$store.getters['getMessagesFetchingDisabled'];
    },
    selectedContactAvatar() {
      if (!this.selectedContact.avatar_upload_date) {
        return cdnSource('@/assets/img/default_avatar.svg');
      }

      return this.baseUrl + `/users/${this.selectedContact.user_id}/avatar`;
    },
    unreadMessages() {
      const messages = this.$store.getters['getMessages'];

      return messages
        .filter((item) => item.incoming && !item.is_read)
        .map((item) => item.id);
    },
    unreadMessagesCounter() {
      return this.unreadMessages.length;
    },
    loading() {
      return this.$store.getters['getMessagesLoading'];
    },
    wasLoaded() {
      return this.$store.getters['getMessagesWasLoaded'];
    }
  },
  methods: {
    setChatToBottom() {
      if (this.$refs.chat) {
        const scrollSize = this.$refs.chat.getScrollSize();

        this.$refs.chat.scrollToOffset(scrollSize);
      }
    },
    goBack() {
      this.$store.dispatch('goBackHistory');
    },
    goProfile() {
      const { user_id } = this.selectedContact;

      this.$store.dispatch('setActiveViewAndPanel', {
        view: "communication",
        panel: "user",
        dataPanel: user_id,
        viewHide: true
      });
    },
    async loadMessages() {
      const messages = this.$store.getters['getMessages'];

      if (this.fetching || this.messagesFetchingDisabled) {
        return;
      }

      this.fetching = true;

      await this.$store.dispatch('loadMessages',  {
        user_id: this.selectedContact.user_id,
        count: -100,
        message_id: messages[0].id,
        order: 'asc'
      });

      this.fetching = false;
    },
    handleScroll() {
      const chat = this.$refs.chat.$el;

      const scroll = chat.scrollHeight - chat.clientHeight;

      this.showFastScroll = scroll - chat.scrollTop > 200;
    }
  },
  watch: {
    userId() {
      this.firstLoading = true;
      this.showFastScroll = false;
    },
    messages() {
      const { unreadMessages, selectedContact } = this;
      const messages = this.$store.getters['getMessages'];
      const lastMessage = messages[messages.length - 1];
      const contacts = [...this.contacts];
      const index = this.contacts
        .map((item) => item.user_id)
        .indexOf(selectedContact.user_id);

      if (
        selectedContact.unread_count > 0
        || unreadMessages.length > 0
      ) {
        this.$store.dispatch('readMessages', this.unreadMessages);
      }

      if (index !== -1) {
        contacts[index].unread_count = 0;
        contacts[index].last_message = lastMessage;
      }

      this.$store.commit('setContacts', contacts);

      if (this.firstLoading) {
        this.firstLoading = false;
        this.$nextTick(this.setChatToBottom);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.c-chat {
  width: 100%;
  height: 100%;

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .header {
    width: 100%;

    margin-bottom: auto;
    padding: 20px 20px 16px 20px;

    position: sticky;
    top: 0;

    align-self: flex-start;
    gap: 16px;

    z-index: 100;

    background: #ffffff;
  }

  .chat {
    overflow-y: auto;

    &-skeleton {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .fetching {
      margin: 20px;

      display: flex;
      justify-content: center;
    }

    &::v-deep .message-wrapper {
      width: 460px;

      margin: 0 auto;

      @media screen and (max-width: 850px) {
        width: 100%;

        padding: 0 20px;
      }
    }
  }

  .messages {
    margin-top: 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .fast-scroll-wrapper {
    width: 460px;

    margin: 0 auto;

    position: relative;

    @media screen and (max-width: 850px) {
      width: 100%;

      padding: 0 20px;
    }

    .fast-scroll {
      width: 30px;
      height: 30px;

      position: absolute;
      right: 0;
      bottom: -91px;

      display: flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;
      transition: .5s ease-in-out;

      border: 1px solid rgba(#1F1F1F, .4);
      border-radius: 50%;

      background: #EFEFEF;

      &-active {
        bottom: 20px;
      }

      .counter {
        padding: 2px 7px;

        display: inline-block;

        position: absolute;
        top: -10px;

        font-family: "Proxima Nova", sans-serif;
        font-weight: 600;
        font-size: 10px;
        line-height: 10px;

        border-radius: 12px;

        color: #FBFBFB;
        background: #1F1F1F;
      }
    }
  }

  .writebar-wrapper {
    padding: 16px 0;

    position: sticky;
    bottom: 0;

    background: #ffffff;

    @media screen and (max-width: 850px) {
      padding: 16px 20px;
    }

    .writebar {
      width: 460px;

      margin: 0 auto;

      @media screen and (max-width: 850px) {
        width: 100% !important;
      }
    }
  }
}

.empty {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 26px;

  .text {
    padding: 8px 16px;

    font-family: "Proxima Nova", sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;

    border-radius: 111px;
    background: #EFEFEF;
  }
}
</style>
