<template>
  <div>
    <skeleton
      class="mail-history-item"
      :height="height"
    />
  </div>
</template>

<script>
import Skeleton from "@/components/Skeleton";

export default {
  components: {
    Skeleton
  },
  data() {
    const height = [100, 150, 200, 250];

    return {
      height: height[this.randomInt(0, height.length)]
    };
  },
  methods: {
    randomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    }
  }
};
</script>

<style lang="scss" scoped>
.mail-history-item {
  width: 360px;

  @media screen and (max-width: 650px) {
    width: 100%;

    padding: 0 20px;
  }
}
</style>
