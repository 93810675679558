import store from "@/store";

import { AxiosError } from "axios";
import { NotificationStateI } from "@/store/notification";

export default async function handleError(
  e: AxiosError,
  position: NotificationStateI["position"] = "tabs"
) {
  if (e.response) {
    const { data, config } = e.response;

    const { error } = data;
    const { url } = config;

    const messages: {
      [key: string]: {
        [key: string]: string
      }
    } = {
      '/auth/token': {
        'not_found': 'Неправильный пароль'
      },
      '/auth/login': {
        'user_not_found': 'Номер не найден'
      },
      '/profile/password': {
        'wrong_old_password': 'Неправильный пароль'
      }
    };

    if (!url) {
      return;
    }

    const text = messages[url]?.[error];

    if (text) {
      await store.dispatch('sendNotification', {
        type: 'error',
        text,
        position
      });

      return;
    }
  }

  if (position === "tabs") {
    if (e.config.headers) {
      delete e.config.headers["Authorization"];
    }

    await store.dispatch('setActiveModal', { modal: 'errorResponse', data: e });
  }
}
