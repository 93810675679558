<template>
  <div>
    <div
      :class="{ 'c-structure': true, 'c-divider': divider, 'c-structure-active': active, 'c-structure-disabled': disabled }"
      @click="!disabled && $emit('click')"
    >
      <Avatar
        :src="avatarUrl"
        :transport="transport"
        :size="36"
      ></Avatar>
      <div class="info" :style="`margin-right: ${after ? 20 : 0}px`">
        <div class="name">{{ source.full_name }}</div>
        <div class="text">{{ source.rank }}</div>
      </div>
      <div v-if="after" class="after">
        <img :src="cdn.after" alt="" />
        <div class="action">
          <div class="item" @click="goProfile">{{ $t('structure.profile') }}</div>
          <div class="item" @click="goChat">{{ $t('structure.chat') }}</div>
        </div>
      </div>
    </div>
    <div class="c-structure-list">
      <slot name="list"></slot>
    </div>
  </div>
</template>

<script>
import cdnSource from "@/functions/cdnSource";

import Avatar from "@/components/Avatar";

export default {
  components: {
    Avatar
  },
  props: {
    active: Boolean,
    divider: Boolean,
    disabled: Boolean,
    source: Object
  },
  data() {
    return {
      cdn: {
        after: cdnSource('@/assets/img/app/after.svg')
      }
    };
  },
  computed: {
    me() {
      return this.$store.getters['getMe'];
    },
    baseUrl() {
      return this.$store.getters['getBaseUrl'];
    },
    avatarUrl() {
      return `${this.baseUrl}/users/${this.source.user_id}/avatar`;
    },
    transport() {
      const {source} = this;

      if (!source.last_message) {
        return false;
      }

      return source.preferred_transport;
    },
    after() {
      const { me, source } = this;

      return me.user_id !== source.user_id;
    }
  },
  methods: {
    preventDefault(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    goProfile(e) {
      const { source } = this;

      this.preventDefault(e);

      this.$store.dispatch('setActiveViewAndPanel', {
        view: "communication",
        panel: "user",
        dataPanel: source.user_id,
        viewHide: true
      });
    },
    goChat(e) {
      const { source } = this;

      this.preventDefault(e);

      this.$store.dispatch('setActiveViewAndPanel', {
        view: "communication",
        panel: "chat"
      });

      this.$store.commit('setSelectedContact', source);
      this.$store.dispatch('loadMessages',  {
        user_id: source.user_id,
        count: 100,
        order: 'asc',
        loading: true
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.c-structure {
  padding: 12px;

  position: relative;

  display: flex;
  align-items: center;

  @media screen and (min-width: 650px) {
    &:hover {
      cursor: pointer;
      border-radius: 16px;
      background: #EFEFEF;
    }
  }

  &-disabled {
    &:hover {
      cursor: default;
      background: transparent;
    }
  }

  &-active {
    border-radius: 16px;
    background: #EFEFEF;
  }

  &-list {
    margin-left: 16px;
  }

  .info {
    margin-left: 12px;

    flex: 1;

    display: flex;
    justify-content: space-between;
    flex-direction: column;

    overflow: hidden;

    .name, .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .name {
      font-family: "Proxima Nova", sans-serif;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
    }

    .text {
      font-family: "Proxima Nova", sans-serif;
      font-size: 14px;
      line-height: 17px;
    }
  }

  .after {
    padding: 5px;

    position: absolute;
    right: 10px;

    cursor: pointer;

    &:hover {
      .action {
        display: block;
        animation: fadeIn .5s forwards;
      }
    }

    .action {
      padding: 12px 0;

      position: absolute;
      top: -5px;
      right: 0;

      display: none;
      opacity: 0;

      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
      border-radius: 8px;

      z-index: 1000;

      transition: visibility 0s, opacity 1s linear;

      background: #FFFFFF;

      .item {
        padding: 6px 14px;

        font-family: "Proxima Nova", sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;

        &:hover {
          background: #EFEFEF;
        }
      }
    }
  }
}

.c-divider {
  // padding + avatar size + design
  $padding: 12px + 36px;

  &:before, &:after {
    content: "";
    z-index: 100;

    background: #E0727F;
  }

  &:before {
    width: 2px;
    height: 14px;

    position: absolute;
    top: $padding + 5px;
    left: 30px;

    border-radius: 20px;
  }

  &:after {
    width: 6px;
    height: 6px;

    position: absolute;
    top: $padding + 9px;
    left: 28px;

    border-radius: 50%;
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
</style>
