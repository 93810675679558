
import axios from "axios";
import { Component, Vue } from "vue-property-decorator";

import SegmentsItem from "@/components/Segments.vue";

import cdnSource from "@/functions/cdnSource";

import { SegmentsDataI, SegmentsUserI } from "@/types/segments";

@Component({
  components: {
    SegmentsItem
  }
})
export default class StructureSegments extends Vue {

  segments: SegmentsDataI | null = null;

  cdn = {
    chevron: cdnSource('@/assets/img/structure/chevron.svg')
  }

  async mounted() {
    const { data } = await axios.get("/structure");

    this.segments = data;
  }

  async loadStructureById(item: SegmentsUserI) {
    item.isOpen = !item.isOpen;

    if (!item.isOpen) {
      return;
    }

    const { data } = await axios.get("/structure/" + item.id);

    item.data = data;

    console.log('loadStructureById');
  }

  async loadStructureOffsetById(item: SegmentsUserI, offset = 0) {
    const { data } = await axios.get("/structure/" + item.id, {
      params: {
        offset
      }
    });

    item.data = {
      ...data,
      users: item.data?.users ? [...item.data?.users, ...data.users] : []
    };
    //
    console.log(item.data);
  }

}
