
import {Component, Prop, Vue} from "vue-property-decorator";

import Structure from "@/components/Structure.vue";

import {StructureUserI} from "@/types/structure";

interface IProps {
  index?: number,
  source: {
    users: Array<StructureUserI>,
    childs: Array<StructureUserI>
  }
}

@Component({
  components: {
    Structure
  }
})
export default class StructureVirtual extends Vue {

  @Prop() index: IProps["index"];
  @Prop({ default: {} }) source!: IProps["source"];

  get me() {
    return this.$store.getters['getMe'];
  }

  get structure() {
    return this.$store.getters['getStructure'];
  }

  get user() {
    return this.structure.childs.user;
  }

  get meIndex() {
    return this.source.users.findIndex((item) => item.user_id === this.me.user_id);
  }

  changeStructure(userId: string) {
    this.$store.dispatch('loadStructure', { userId, updateParents: false });
  }

}
