<template>
  <div class="structureList">
    <virtual-list
      v-if="!structureLoading"

      class="list scroll"
      :style="'height: ' + structureHeight + '!important;'"

      :data-key="'key'"
      :data-sources="structureVirtual"
      :data-component="itemComponent"

      @tobottom="loadStructure"
    >
      <div slot="footer" v-show="fetching" class="fetching">
        <Spinner :absolute="false" />
      </div>
    </virtual-list>
    <skeleton-structure-virtual v-else class="list"></skeleton-structure-virtual>
  </div>
</template>

<script>
import cdnSource from "@/functions/cdnSource";

import Burger from "@/components/Burger";
import Search from "@/components/Search";
import Spinner from "@/components/Spinner";
import SkeletonStructureVirtual from "@/components/Skeleton/StructureVirtual";
import StructureVirtual from "@/components/VirtualList/StructureVirtual";

export default {
  components: {
    Burger,
    Search,
    Spinner,
    SkeletonStructureVirtual
  },
  data() {
    return {
      structureHeight: '360px',
      cdn: {
        search: cdnSource('@/assets/img/app/search.svg')
      },
      itemComponent: StructureVirtual,
      ro: null,
      fetching: false
    };
  },
  mounted() {
    this.ro = new ResizeObserver(this.calculateStructureHeight);
    this.ro.observe(this.$parent.$refs.structure);
  },
  computed: {
    structure() {
      return this.$store.getters['getStructure'];
    },
    structureLoading() {
      return this.$store.getters['getStructureLoading'];
    },
    users() {
      return this.structure.parents.users;
    },
    user() {
      return this.structure.childs.user;
    },
    childs() {
      return this.structure.childs.childs;
    },
    structureVirtual() {
      return [{
        key: 'random',
        users: this.users,
        childs: this.childs
      }];
    },
    structureFetchingDisabled() {
      return this.$store.getters['getStructureFetchingDisabled'];
    }
  },
  methods: {
    async loadStructure() {
      if (this.fetching || this.fetchingDisabled) {
        return;
      }

      this.fetching = true;

      await this.$store.dispatch('loadStructure', { userId: this.user.user_id, offset: this.childs.length });

      this.fetching = false;
    },
    calculateStructureHeight() {
      if (!this.$refs.structure) {
        return;
      }

      this.structureHeight = (this.$refs.structure.clientHeight - 72) + 'px';
    },
    changeSearch(value) {
      this.$store.commit('setSubPanel', value);
    }
  }
};
</script>

<style lang="scss" scoped>
.structureList {
  margin-top: 10px;

  .list {
    height: 433px;

    padding: 0 8px;

    display: flex;
    flex-direction: column;

    overflow-y: auto;

    .fetching {
      margin: 10px;

      display: flex;
      justify-content: center;
    }
  }
}
</style>
