
import axios from "axios";
import { Component, Vue, Prop, Ref } from "vue-property-decorator";

import cdnSource from "@/functions/cdnSource";

import {AppStateI} from "@/store/app";

interface IProps {
  tabs: Array<{
    view: string,
    panel?: string,
    disableView?: boolean
  }>,
  views: Array<string>,
  panels: Array<string>
}

interface IState {
  tabIcons: Array<string>,
  intervalId: ReturnType<typeof setInterval>
}

@Component
export default class Tabs extends Vue {

  @Ref() readonly template!: HTMLDivElement;

  @Prop({ required: true }) tabs!: IProps["tabs"];
  @Prop({ required: true }) views!: IProps["views"];
  @Prop({ required: true }) panels!: IProps["panels"];

  tabIcons!: IState["tabIcons"];
  intervalId!: IState["intervalId"];

  constructor() {
    super();

    this.tabIcons = this.tabs.map((item) => cdnSource(`@/assets/img/tabs/${item.view}.svg`));
  }

  get me() {
    return this.$store.getters['getMe'];
  }

  get app(): AppStateI {
    return this.$store.state.app;
  }

  get mode() {
    return this.$store.getters['getMode'];
  }

  get toUser() {
    return this.$store.getters['getToUser'];
  }

  get contacts() {
    return this.$store.getters['getContacts'];
  }

  get firstContactSelected() {
    return this.$store.getters['getFirstContactSelected'];
  }

  mounted() {
    this.firstContactSelect();

    this.handleEventResize();

    window.addEventListener("resize", this.handleEventResize, false);
    window.addEventListener("keydown", this.handleEventKeyDown, false);
  }

  destroy() {
    window.removeEventListener("resize", this.handleEventResize);
    window.removeEventListener("keydown", this.handleEventKeyDown);
  }

  handleEventResize() {
    const { activePanel } = this.app;

    const app = this.$refs.template as HTMLDivElement;
    const width = app.clientWidth;

    if (!activePanel && width > 650) {
      this.$store.dispatch('setActivePanel', { panel: "empty" });
    }

    if (activePanel === "empty" && width <= 650) {
      this.$store.dispatch('setActivePanel', { panel: "" });
    }

    this.$store.commit('changeMobile', width <= 650);
  }

  handleEventKeyDown(e: KeyboardEvent) {
    const { activeModal } = this.app;

    if (activeModal && e.key === 'Escape') {
      this.goBack();
    }
  }

  changeTab(item: IProps["tabs"][0]) {
    // Check if user not loaded disable tabs
    if (!this.me) {
      return;
    }

    if (item.view === 'structure') {
      this.$store.dispatch('loadStructure', { userId: this.me.user_id, updateParents: true });
    }

    this.$store.dispatch('setActiveView', {
      view: item.view,
      viewHide: item.disableView
    });

    if (item.panel) {
      this.$store.dispatch('setActivePanel', { panel: item.panel });
    }
  }

  async firstContactSelect() {
    await this.$store.dispatch('loadContacts', {
      intervalUpdate: true
    });

    if (this.firstContactSelected) {
      return;
    }

    this.$store.commit('setFirstContactSelected', true);

    if (!this.toUser) {
      return;
    }

    let findContact = this.contacts.find((item: any) => item.user_id === this.toUser);

    if (!findContact) {
      try {
        const { data } = await axios.get(`/users/${this.toUser}`);

        findContact = data;
      } catch (e) {
        findContact = {
          user_id: this.toUser,
          notExist: true
        };
      }
    }

    this.selectContact(findContact);
  }

  selectContact(contact: any) {
    this.$store.dispatch('setActivePanel', { panel: 'chat' });
    this.$store.commit('setSelectedContact', contact);

    this.$store.dispatch('loadMessages',  {
      user_id: contact.user_id,
      count: 100,
      order: 'asc',
      loading: true
    });

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    this.intervalId = setInterval(() => {
      const { user_id } = this.$store.getters['getSelectedContact'];
      this.$store.dispatch('loadMessages',  {
        user_id: user_id,
        count: 100,
        order: 'asc',
        intervalUpdate: true
      });
    }, this.$store.getters['getUpdateInterval']);
  }

  goBack() {
    this.$store.dispatch('goBackHistory');
  }

}
