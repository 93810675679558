import { TranslateResult } from "vue-i18n";

import i18n from "@/plugins/i18n";

const statList: Array<{
  key: string,
  status: string,
  name: TranslateResult,
  percent?: number
}> = [
  {
    key: 'recipient_count',
    status: 'recipient',
    name: i18n.t('statsStates.recipient')
  },
  {
    key: 'sent_count',
    status: 'sent',
    name: i18n.t('statsStates.sent')
  },
  {
    key: 'delivered_count',
    status: 'delivered',
    name: i18n.t('statsStates.delivered')
  },
  {
    key: 'read_count',
    status: 'read',
    name: i18n.t('statsStates.read')
  },
  {
    key: 'not_delivered_count',
    status: 'not_delivered',
    name: i18n.t('statsStates.notDelivered')
  },
  {
    key: 'failed_count',
    status: 'failed',
    name: i18n.t('statsStates.failed')
  },
  {
    key: 'no_data_found_count',
    status: 'info_not_found',
    name: i18n.t('statsStates.notFound')
  }
];

export default statList;
