var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Tabs',{attrs:{"tabs":[
    {
      view: 'communication'
    },
    {
      view: 'structure'
    },
    {
      view: 'profile',
      panel: 'profile',
      disableView: true
    }
  ],"views":['communication', 'structure'],"panels":['chat', 'empty', 'profile', 'editNote']},scopedSlots:_vm._u([{key:"communication",fn:function(){return [_c('communication')]},proxy:true},{key:"structure",fn:function(){return [_c('structure')]},proxy:true},{key:"chat",fn:function(){return [_c('chat')]},proxy:true},{key:"empty",fn:function(){return [_c('empty')]},proxy:true},{key:"user",fn:function(){return [_c('user')]},proxy:true},{key:"profile",fn:function(){return [_c('profile')]},proxy:true},{key:"editNote",fn:function(){return [_c('edit-note')]},proxy:true},{key:"mailHistory",fn:function(){return [_c('mail-history')]},proxy:true},{key:"mailInfo",fn:function(){return [_c('mail-info')]},proxy:true},{key:"statInfo",fn:function(){return [_c('stat-info')]},proxy:true},{key:"statSend",fn:function(){return [_c('stat-send')]},proxy:true},{key:"broadcastList",fn:function(){return [_c('prohibition-list')]},proxy:true},{key:"successStatSend",fn:function(){return [_c('success-stat-send')]},proxy:true},{key:"changePassword",fn:function(){return [_c('change-password')]},proxy:true},{key:"blockBalance",fn:function(){return [_c('block-balance')]},proxy:true},{key:"confirmLogout",fn:function(){return [_c('confirm-logout')]},proxy:true},{key:"errorResponse",fn:function(){return [_c('error-response')]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }