<template>
  <div id="app">
    <app-child v-bind="$props"></app-child>
  </div>
</template>

<script>
import AppChild from "@/AppChild.vue";

const isDev = process.env.NODE_ENV !== 'production';

export default {
  props: {
    baseUrl: {
      type: String,
      required: !isDev
    },
    cdnUrl: {
      type: String,
      required: !isDev
    },
    locale: {
      type: String
    },
    mode: {
      type: String,
      required: false
    },
    token: {
      type: String,
      required: !isDev
    },
    toUser: {
      type: String,
      required: false
    },
    updateInterval: {
      type: [Number, String],
      default: 30
    }
  },
  components: {
    AppChild
  }
};
</script>
