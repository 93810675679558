
import { Component, Prop, Vue } from "vue-property-decorator";

import Structure from "@/components/Structure.vue";

import cdnSource from "@/functions/cdnSource";

import { SegmentsUserI } from "@/types/segments";

@Component({
  components: {
    SegmentsItem,
    Structure
  }
})
export default class SegmentsItem extends Vue {

  @Prop({ required: true }) item!: SegmentsUserI;
  @Prop({ required: true }) loadStructureById!: Function;
  @Prop({ required: true }) loadStructureOffsetById!: Function;

  cdn = {
    chevron: cdnSource('@/assets/img/structure/chevron.svg'),
    loading: cdnSource('@/assets/img/structure/loading.svg')
  }

  async loadStructure(e: MouseEvent, item: SegmentsUserI, isOffset = false) {
    e.preventDefault();
    e.stopPropagation();

    item.isLoading = true;

    this.$forceUpdate();

    if (!isOffset) {
      await this.loadStructureById(item);
    } else {
      await this.loadStructureOffsetById(item, item.data?.users.length);
    }

    item.isLoading = false;

    this.$forceUpdate();
  }

}
