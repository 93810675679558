
import axios from "axios";
import { Component, Prop, Vue } from "vue-property-decorator";

import TabsScreen from "@/screens/Tabs.vue";
import AuthScreen from "@/screens/Auth.vue";

import Notification from "@/components/Notification.vue";

const isDev = process.env.NODE_ENV !== 'production';

interface IProps {
  baseUrl: string,
  cdnUrl: string,
  locale: string,
  token: string,
  toUser: string,
  mode: "web" | "widget" | "chat",
  updateInterval: number | string
}

@Component({
  components: {
    Notification,
    TabsScreen,
    AuthScreen
  }
})
export default class AppChild extends Vue {

  @Prop({ required: !isDev }) baseUrl!: IProps["baseUrl"];
  @Prop({ required: !isDev }) cdnUrl!: IProps["cdnUrl"];
  @Prop({ required: !isDev }) token!: IProps["token"];

  @Prop() locale!: IProps["locale"];
  @Prop({ default: 30 }) updateInterval!: IProps["updateInterval"];

  @Prop() toUser!: IProps["toUser"];
  @Prop() mode!: IProps["mode"];

  get activeScreen() {
    return this.$store.state.app.activeScreen;
  }

  async created() {
    const baseUrl = this.baseUrl ?? process.env.VUE_APP_BASE_URL;
    const token = this.token ?? process.env.VUE_APP_TOKEN;
    const mode = this.mode ?? process.env.VUE_APP_MODE;
    const locale = this.locale ?? process.env.VUE_APP_I18N_LOCALE;
    const toUser = this.toUser ?? process.env.VUE_APP_TO_USER;

    this.$store.commit('setWidgetConfig', {
      baseUrl,
      cdnUrl: this.cdnUrl ?? process.env.VUE_APP_CDN_URL,
      token,
      toUser: mode !== 'web' ? toUser : undefined,
      updateInterval: this.updateInterval,
      mode
    });

    this.$i18n.locale = locale.toLocaleLowerCase();

    axios.defaults.baseURL = baseUrl;

    await this.$store.dispatch('loadConfig');

    this.$root.$moment.locale(this.$i18n.locale);

    if (mode === 'web') {
      const token = localStorage.getItem('token');

      if (token) {
        try {
          await axios.get("profile", {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

          await this.$store.commit('setWidgetConfig', {
            token
          });

          this.$store.commit('setActiveScreen', { screen: 'tab' });
        } catch (e) {
          localStorage.removeItem('token');

          this.$store.commit('setActiveScreen', { screen: 'login' });

          console.log(e);
        }
      } else {
        this.$store.commit('setActiveScreen', { screen: 'login' });
      }

      return;
    }

    this.$store.commit('setActiveScreen', { screen: 'tab' });
  }

}
