<template>
  <div v-if="mode !== 'chat'" class="header">
    <slot name="default"></slot>
    <div class="user" @click="$emit('click-user')">
      <slot name="avatar"></slot>
      <div class="info">
        <div class="name">{{ name }}</div>
        <div class="description">{{ description }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false
    }
  },
  computed: {
    mode() {
      return this.$store.getters['getMode'];
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  position: relative;

  display: flex;
  align-items: center;

  &::before {
    width: calc(100% - 20px * 2);
    height: 1px;

    position: absolute;
    left: 20px;
    bottom: 0;

    content: "";

    background: rgba(31, 31, 31, 0.1);

    @media screen and (max-width: 650px) {
      width: 100%;

      left: 0;
    }
  }

  .user {
    display: flex;
    gap: 12px;

    cursor: pointer;

    overflow: hidden;
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;

    overflow: hidden;

    .name, .description {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .name {
      font-family: "Proxima Nova", sans-serif;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;

      color: #1f1f1f;
    }

    .description {
      font-family: "Proxima Nova", sans-serif;
      font-size: 14px;
      line-height: 17px;

      color: #585757;
    }
  }
}
</style>
