<template>
  <div class="structure-wrapper">
    <div class="structure" ref="structure">
      <div class="title">
        <Burger class="burger"></Burger>
        {{ $t('tabs.structure') }}
        <div class="search" :title="$t('tooltips.search')"  @click="changeSearch(true)">
          <img :src="cdn.search" alt="">
        </div>
      </div>
      <ViewTabs v-model="activeTab" :tabs="['Список', 'Сегменты']" />
      <StructureList v-if="activeTab === 0" />
      <StructureSegments v-else />
    </div>
    <Search :class="{ 'c-search-active': search }" @close="changeSearch(false)"></Search>
  </div>
</template>

<script>
import Burger from "@/components/Burger";
import Search from "@/components/Search";
import ViewTabs from "@/components/ViewTabs";

import StructureList from "@/views/Structure/List";
import StructureSegments from "@/views/Structure/Segments.vue";

import cdnSource from "@/functions/cdnSource";

export default {
  components: {
    ViewTabs,
    Burger,
    Search,
    StructureList,
    StructureSegments
  },
  data() {
    return {
      activeTab: 0,
      cdn: {
        search: cdnSource('@/assets/img/app/search.svg')
      }
    };
  },
  computed: {
    me() {
      return this.$store.getters['getMe'];
    },
    search() {
      return this.$store.state.app.subPanel;
    },
    childsCount() {
      return this.$store.getters['getStructureChildsCount'];
    },
    structure() {
      return this.$store.getters['getStructure'];
    },
    structureLoading() {
      return this.$store.getters['getStructureLoading'];
    },
    users() {
      return this.structure.parents.users;
    },
    user() {
      return this.structure.childs.user;
    },
    childs() {
      return this.structure.childs.childs;
    },
    meIndex() {
      let meIndex = -1;

      this.users.forEach((item, index) => {
        if (item.user_id !== this.me.user_id) {
          return;
        }

        meIndex = index;
      });

      return meIndex;
    },
    structureVirtual() {
      return [{
        key: 'random',
        users: this.users,
        childs: this.childs
      }];
    },
    structureFetchingDisabled() {
      return this.$store.getters['getStructureFetchingDisabled'];
    }
  },
  methods: {
    async loadStructure() {
      if (this.fetching || this.fetchingDisabled) {
        return;
      }

      this.fetching = true;

      await this.$store.dispatch('loadStructure', { userId: this.user.user_id, offset: this.childs.length });

      this.fetching = false;
    },
    calculateStructureHeight() {
      if (!this.$refs.structure) {
        return;
      }

      this.structureHeight = (this.$refs.structure.clientHeight - 72) + 'px';
    },
    changeSearch(value) {
      this.$store.commit('setSubPanel', value);
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  margin: 26px 20px 16px 20px;

  display: flex;
  align-items: center;
  gap: 8px;

  font-family: "Proxima Nova", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  .burger {
    margin-right: 18px;
  }

  .counter {
    padding: 4px 7px;

    display: inline-block;

    font-family: "Proxima Nova", sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 10px;

    border-radius: 12px;

    color: #FBFBFB;
    background: #1F1F1F;
  }

  .search {
    margin-left: auto;

    cursor: pointer;
  }
}

.structure {
  width: 100%;
  height: inherit;

  display: flex;
  flex-direction: column;

  &-wrapper {
    height: 100%;
  }
}
</style>
